//GSAP REGISTER PLUGINS

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(Draggable);

ScrollTrigger.defaults({
  markers: false,
});

/*




vars




*/

// Loading overlay
let loadingOverlay = $(".n-loading-overlay");
// Homepage navigation
const globalLogo = $(".n-logo-wrapper");
const navigationLinksWrapper = $(".n-nav-wrapper");

// footer
const footerBtns = $(".n-footer-btn");

// global colors
const hoverColor = "#E9EFA2";
const darkColor = "#203229";

// IN FOOTER BTNS
footerBtns.each(function (index) {
  let targetElement = $(this);

  let tl = gsap.timeline({
    paused: true,
  });

  tl.to(
    targetElement,
    {
      duration: 0.3,
      ease: "expo.out",
      //color: "#E9EFA2",
      scale: 1,
      //x: 10,
    },
    0,
  );

  targetElement.mouseover(function () {
    tl.play();
  });
  targetElement.mouseout(function () {
    tl.reverse();
  });
});

/*  





Global Btn rollover





*/

function ncBtnInit() {

  const ncBtn = $('.nc-btn');

  $(ncBtn).each(function () {
    let targetElement = $(this);

    let tl = gsap.timeline({
      paused: true,
    });

    tl.to(
      targetElement,
      {
        duration: 0.3,
        ease: "expo.out",
        color: "#222125",
        background: "#fbd784",
        //y: "-=5",
      },
      0,
    );

    targetElement.mouseover(function () {
      tl.play();
    });
    targetElement.mouseout(function () {
      tl.reverse();
    });
  });

}

if ($('.nc-btn')[0]) {
  ncBtnInit();
}

/* 



FORM overlay



*/
const formOverlay = $('.n-overlay-form-officials');

function formOveralyInit(tar) {

  gsap.set(tar, { left: 0, autoAlpha: 0 });

  let formCon = $('.n-form-con');
  let formBtn = $('.form-launch-cta');
  let overlayClose = $('.n-overlay-close');

  let tl = gsap.timeline({
    paused: true,
  });

  tl.to(
    formOverlay,
    {
      duration: 0.3,
      ease: "expo.out",
      autoAlpha: 1,
    },
    0,
  );

  formBtn.mousedown(function () {
    tl.restart();
  });

  overlayClose.mousedown(function () {
    tl.reverse();
  });

}

if ($(formOverlay)[0]) {
  formOveralyInit(formOverlay);
}

/*



HOW do officials overlay



*/

const formOverlay2 = $('.n-how-elected-section');

function formOveralyInitHowOfficials(tar) {

  gsap.set(tar, { left: 0, autoAlpha: 0 });

  let formCon = $('.n-how-elected-section');
  let formBtn = $('.n-state-how-officials-cta');
  let overlayClose = $('.n-close-btn');

  let tl = gsap.timeline({
    paused: true,
  });

  tl.to(
    formOverlay2,
    {
      duration: 0.3,
      ease: "expo.out",
      autoAlpha: 1,
    },
    0,
  );

  formBtn.mousedown(function () {
    console.log('clicked');
    tl.restart();
  });

  overlayClose.mousedown(function () {
    tl.reverse();
  });

}

if ($(formOverlay2)[0]) {
  formOveralyInitHowOfficials(formOverlay2);
}

/*





MAP - About Wolves





*/
function initMap() {

  let maps = $('.n-map');

  let loop = false;

  let triggerElement = $(".n-maps-wrapper");
  let mapCurrent = $('.map-current');
  let mapHistoric = $('.map-historic');
  let mapPotential = $('.map-potential');
  let mapAll = $('.map-all');
  let mapRed = $('.map-red');
  let mapMexican = $('.map-mexican');

  let buttonCurrent = $('.button-current');
  let buttonHistoric = $('.button-historic');
  let buttonPotential = $('.button-potential');
  let buttonMexican = $('.button-mexican');
  let buttonRed = $('.button-red');
  let buttonAll = $('.button-all');

  let buttonMap = $('map-button');

  gsap.set(maps, { opacity: 0 });

  let tl = gsap.timeline({ paused: true });

  tl.to(
    mapHistoric,
    {
      duration: 0.4,
      delay: 1.5,
      ease: "expo.out",
      opacity: 1,
    },

  );

  tl.to(
    mapCurrent,
    {
      duration: 0.4,
      ease: "expo.out",
      opacity: 1,
    }, ">.5"
  );

  tl.to(
    mapPotential,
    {
      duration: 0.4,
      ease: "expo.out",
      opacity: 1,
    }, ">2"
  ).addPause(">1");

  function repeat() {
    if (loop) tl.reverse();
  }

  function play() {
    if (loop) tl.play();
  }

  //
  //
  //

  buttonCurrent.mousedown(function () {
    loop = false;
    gsap.to(mapCurrent, { opacity: 1 });
    gsap.set(maps, { opacity: 0 });
    tl.pause();
  });

  buttonHistoric.mousedown(function () {
    loop = false;
    gsap.set(maps, { opacity: 0 });
    gsap.to(mapHistoric, { opacity: 1 });
    tl.pause();
  });

  buttonPotential.mousedown(function () {
    loop = false;
    gsap.set(maps, { opacity: 0 });
    gsap.to(mapPotential, { opacity: 1 });
    tl.pause();
  });

  buttonRed.mousedown(function () {
    loop = false;
    gsap.set(maps, { opacity: 0 });
    gsap.to(mapRed, { opacity: 1 });
    tl.pause();
  });

  buttonMexican.mousedown(function () {
    loop = false;
    gsap.set(maps, { opacity: 0 });
    gsap.to(mapMexican, { opacity: 1 });
    tl.pause();
  });

  buttonAll.mousedown(function () {
    loop = false;
    gsap.set(maps, { opacity: 0 });
    gsap.to(mapAll, { opacity: 1 });
    tl.pause();
  });

  buttonMap.mouseover(function () {
    // gsap.to(mapAll, { opacity: 1 });
  });

}

if ($('.n-map')[0]) { initMap(); }

/*





HOMEPAGE MAP LINKS 





*/
function initMapLinks() {
  $('.map__state').click(function () {
    var pageUrl = "/states-wolf-pages/" + $(this).attr('state-name').toLowerCase().replace(
      /\s/g,
      '-');
    //console.log('pageUrl = ' + pageUrl);
    window.location.href = pageUrl;
  });
}
if ($('.map__state')[0]) { initMapLinks(); }

/*




wolves-pop




*/

function wolvesInStoryOverlayOpen() {

  let open = false;

  let targetElement = $('.wolves-pop');
  let targetBtn = $('.wolves-btn');

  let tl = gsap.timeline({ paused: true, });

  tl.set(targetElement, {
    left: 0,
    display: "none",
    autoAlpha: 0,
  });
  tl.to(targetElement, {
    duration: 0.3,
    ease: "cubic.out",
    display: 'flex',
    autoAlpha: 1,
  });

  targetBtn.mousedown(function () {

    if (open) {
      tl.reverse();
      open = false;
    } else {
      tl.restart();
      open = true;
    }
    console.log('clicked wolves');

  });

  targetElement.mousedown(function () {

    if (open) {
      tl.reverse();
      open = false;
    } else {
      tl.restart();
      open = true;
    }

  });
}
if ($('.wolves-pop')[0]) {
  wolvesInStoryOverlayOpen();
}

/*  




Collections Navigaton (anchor scroll) in Timeline




*/
function initTimeline() {
  $(".timeline-btn").each(function (index) {
    let targetElement = $(this);
    let tarSec = "#a" + targetElement.attr("targetSection");

    console.log("tarSec: " + tarSec);
    // let order = targetElement.attr("order");
    //let targetElement2 = $(this).find(".timeline-btn");
    //let targetElement3 = $(this).find(".nc-collection-nav-label");

    let tl = gsap.timeline({
      paused: true,
    });

    targetElement.on("click", function (e) {
      gsap.to(window, {
        duration: 4,
        scrollTo: {
          y: tarSec,
          ease: "power1.out",
          offsetY: 100
        },
        ease: "expo.out",
      });
    });

  });
}
if ($('.timeline-btn')[0]) {
  navigationDrops();
}

/*


NAVIGATION DROP DOWNS


*/

function navigationDrops() {

  /*
  gsap.set(".nav-drop", {
    autoAlpha: 0,
  });
  */

  $(".nav-btn-top").each(function (index) {
    let targetElement = $(this);
    let navDropElement = targetElement.find(".nav-drop");

    targetElement.on("click", function (e) {

      gsap.set(".nav-drop", {
        zIndex: 10,
        display: 'none',
      });
      gsap.to(navDropElement, {
        duration: .2,
        zIndex: 90,
        display: 'flex',
        ease: "power1.out",
      });

      console.log('clicked')

    });

    navDropElement.on("mouseleave", function () {

      gsap.to(navDropElement, {
        duration: .2,
        display: 'none',
        ease: "power1.out",
      });

    });

  });
}
if ($('.nav-drop')[0]) {
  navigationDrops();
}

function mobileNav() {
  // Initial setup
  gsap.set(".nav-m-links-con", {
    height: 0,
  });

  gsap.set(".nav-m-close-con", {
    autoAlpha: 0
  });

  $(".nav-m-hamburger").each(function () {
    const targetElement = $(this);
    const mobileNavCon = $(".nav-m-links-con");
    const mobileCloseCon = $('.nav-m-close-con');

    const tl = gsap.timeline({ paused: true });

    tl.to(targetElement, {
        duration: 0.1,
        opacity: 0,
        ease: "power1.out"
      })
      .to(mobileNavCon, {
        duration: 0.3,
        height: 'auto',
        display: 'flex',
        ease: "power1.out"
      })
      .to(mobileCloseCon, {
        duration: 0.1,
        autoAlpha: 1,
        display: 'flex',
        ease: "power1.out"
      });

    targetElement.on("click", function () {

      tl.restart();

    });

    mobileCloseCon.on("click", function () {

      console.log('clicked close');

      tl.reverse();

      // Create timeline for smooth animation sequence

    });
  });
}

if (window.innerWidth < 500) {
  mobileNav();
}

/*



hide show animation



*/

const animateNavigation = () => {
  const navGlobal = document.querySelector('.nav-global');
  const navHeight = navGlobal.offsetHeight + 100;
  let lastScrollTop = 0;
  let isAnimating = false;

  // Initial state - ensure nav is visible at the start
  gsap.set(navGlobal, {
    y: 0,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 900
  });

  ScrollTrigger.create({
    start: 'top top',
    end: 'bottom bottom',
    onUpdate: (self) => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      // Don't trigger if we're at the very top of the page
      if (scrollTop < navHeight) {
        gsap.to(navGlobal, {
          y: 0,
          duration: 0.5,
          ease: 'power2.out'
        });
        lastScrollTop = scrollTop;
        return;
      }

      // Prevent multiple animations from running simultaneously
      if (isAnimating) return;

      if (scrollTop > lastScrollTop) {
        // Scrolling Down - Hide Nav
        isAnimating = true;
        gsap.to(navGlobal, {
          y: -navHeight,
          duration: 0.3,
          ease: 'power2.out',
          onComplete: () => {
            isAnimating = false;
          }
        });

        gsap.set(".nav-drop", {
          zIndex: 10,
          display: 'none',
        });
      } else {
        // Scrolling Up - Show Nav
        isAnimating = true;
        gsap.to(navGlobal, {
          y: 0,
          duration: 0.3,
          ease: 'power2.out',
          onComplete: () => {
            isAnimating = false;
          }
        });
      }

      lastScrollTop = scrollTop;
    }
  });
};

// Initialize the navigation animation
animateNavigation();

/*




 NEW HOMEPAGE MAP CODE




*/
function initDragMap2() {

  const mapImages = document.querySelectorAll(".map-image");
  const scrollbar = document.getElementById("scrollbar");
  const scrollbarThumb = document.getElementById("scrollbar-thumb");
  const scrollbarMarkers = document.getElementById("scrollbar-markers");
  const contentElements = document.querySelectorAll(".content");

  const numStops = mapImages.length;
  let isDragging = false;

  // Create markers for each stop
  for (let i = 0; i < numStops; i++) {
    const marker = document.createElement("div");
    marker.classList.add("scrollbar-marker");
    scrollbarMarkers.appendChild(marker);
  }

  // Function to update map and content based on scrollbar position
  function updateMap(position) {
    const scrollbarWidth = scrollbar.offsetWidth - scrollbarThumb.offsetWidth;
    const progress = position / scrollbarWidth; // Progress between 0 and 1

    // Calculate the active stop and the interpolation factor
    const activeStop = progress * (numStops - 1);
    const currentIndex = Math.floor(activeStop);
    const nextIndex = Math.min(currentIndex + 1, numStops - 1);
    const interpolationFactor = activeStop - currentIndex;

    // Update map images' opacity
    mapImages.forEach((image, index) => {
      if (index === currentIndex) {
        gsap.to(image, { opacity: 1 - interpolationFactor, duration: 0 });
      } else if (index === nextIndex) {
        gsap.to(image, { opacity: interpolationFactor, duration: 0 });
      } else {
        gsap.to(image, { opacity: 0, duration: 0 });
      }
    });

    // Update content visibility
    contentElements.forEach((content, index) => {
      if (Math.round(activeStop) === index && interpolationFactor === 0) {
        content.classList.add("active"); // Show content only when exactly on a stop
      } else {
        content.classList.remove("active"); // Hide content during transitions
      }
    });
  }

  // Scrollbar drag functionality
  scrollbarThumb.addEventListener("mousedown", function (e) {
    isDragging = true;
    document.addEventListener("mousemove", onDrag);
    document.addEventListener("mouseup", onDragEnd);
  });

  function onDrag(e) {
    if (!isDragging) return;
    const scrollbarRect = scrollbar.getBoundingClientRect();
    let newPosition = e.clientX - scrollbarRect.left - scrollbarThumb.offsetWidth / 2;
    newPosition = Math.max(0, Math.min(newPosition, scrollbar.offsetWidth - scrollbarThumb
      .offsetWidth));
    scrollbarThumb.style.left = `${newPosition}px`;
    updateMap(newPosition);
  }

  function onDragEnd() {
    isDragging = false;
    document.removeEventListener("mousemove", onDrag);
    document.removeEventListener("mouseup", onDragEnd);

    // Snap to the nearest stop when dragging ends
    const scrollbarWidth = scrollbar.offsetWidth - scrollbarThumb.offsetWidth;
    const currentPosition = parseFloat(scrollbarThumb.style.left) || 0;
    const progress = currentPosition / scrollbarWidth;
    const activeStop = Math.round(progress * (numStops - 1));
    const snapPosition = (activeStop / (numStops - 1)) * scrollbarWidth;

    gsap.to(scrollbarThumb, {
      left: snapPosition,
      duration: 0.3,
      onUpdate: () => updateMap(snapPosition),
      onComplete: () => updateMap(snapPosition),
    });
  }

  // Initial position
  updateMap(0);

}
if ($('.maps')[0]) {
  initDragMap2();
}
/*





CAROUSELs





*/

function initCarousel() {

  const carousel = document.querySelector('.carousel');
  const cards = document.querySelectorAll('.carousel-card');
  const prevButton = document.querySelector('.carousel-button.prev');
  const nextButton = document.querySelector('.carousel-button.next');

  // Set the initial currentIndex to the rightmost card
  let currentIndex = cards.length - 1; // Assuming 3 cards are visible at a time

  function updateCarousel() {
    gsap.killTweensOf(cards);

    cards.forEach((card, index) => {
      let offset = index - currentIndex;
      let x = offset * 170; // Card width (150px) for seamless transition
      let scale = offset === 0 ? 1 : 0.4; // Current card is larger
      let zIndex = offset === 0 ? 1 : 0; // Current card is on top
      let opacity = offset === 0 ? 1 : 1; // Current card is fully visible

      gsap.to(card, {
        x: x,
        scale: scale,
        zIndex: zIndex,
        opacity: opacity,
        duration: 0.5,
        ease: "power2.out"
      });
    });
  }

  prevButton.addEventListener('click', () => {
    currentIndex = (currentIndex - 1 + cards.length) % cards.length;
    updateCarousel();
  });

  nextButton.addEventListener('click', () => {
    currentIndex = (currentIndex + 1) % cards.length;
    updateCarousel();
  });

  // Initialize the carousel with the rightmost card as the current card
  updateCarousel();

}
if ($('.carousel')[0]) {
  initCarousel();
}
/* 





GLOBAL scrollTrigger animations





*/
function initStrollTrigger() {

  // Select all elements with animation classes
  const animateInElements = document.querySelectorAll('.animate-in');
  const animateOutElements = document.querySelectorAll('.animate-out');
  const animateBothElements = document.querySelectorAll('.animate-both');

  // Animate in: Fade in and move up
  animateInElements.forEach((element) => {
    gsap.from(element, {
      opacity: 0,
      y: 50,
      duration: 1,
      scrollTrigger: {
        trigger: element,
        start: 'top 80%', // When the top of the element hits 80% of the viewport
        end: 'top 20%', // When the top of the element hits 20% of the viewport
        toggleActions: 'play none none reverse', // Play on enter, reverse on leave
      },
    });
  });

  // Animate out: Fade out and move up
  animateOutElements.forEach((element) => {
    gsap.to(element, {
      opacity: 0,
      y: -50,
      duration: 1,
      scrollTrigger: {
        trigger: element,
        start: 'top top', // When the top of the element hits the top of the viewport
        end: 'bottom top', // When the bottom of the element hits the top of the viewport
        toggleActions: 'play none none reverse', // Play on enter, reverse on leave
      },
    });
  });

  // Animate both in and out for each element
  animateBothElements.forEach((element) => {
    // Animate in: Fade in and move up
    gsap.from(element, {
      opacity: 0,
      y: 50,
      duration: 1,
      scrollTrigger: {
        trigger: element,
        start: 'top 90%', // Start when the top of the element is 90% down the viewport
        end: 'top 20%', // End when the top of the element is 20% down the viewport
        toggleActions: 'play none none reverse', // Play on enter, reverse on leave
      },
    });

    // Animate out: Fade out and move up
    gsap.to(element, {
      opacity: 0,
      y: -50,
      duration: 1,
      scrollTrigger: {
        trigger: element,
        start: 'top 10%', // Start when the top of the element is 10% down the viewport
        end: 'bottom top', // End when the bottom of the element hits the top of the viewport
        toggleActions: 'play none none reverse', // Play on enter, reverse on leave
      },
    });
  });

}
if ($('.animate-in')[0] || $('.animate-out')[0]) {
  initStrollTrigger();
}

/*


HERO ANIMATE out


*/
function initHero() {
  // Select the n-hero element
  const nHero = document.querySelector('.n-hero');

  // Animate out the n-hero on scroll
  gsap.to(nHero, {
    opacity: 0, // Fade out
    y: -100, // Optional: Move up slightly
    scale: 0.9, // Optional: Scale down
    duration: 1, // Animation duration
    scrollTrigger: {
      trigger: nHero, // Use the n-hero as the trigger
      start: 'top top', // Start animation when the top of the n-hero hits the top of the viewport
      end: 'bottom top', // End animation when the bottom of the n-hero hits the top of the viewport
      scrub: true, // Smoothly animate as the user scrolls
    },
  });
}
/*



HOMEPAGE TITLE animations



*/

function initHomepage() {

  // Select the elements
  const homePageTitle = document.querySelector('.home-page-title');
  const homeSubhead = document.querySelector('.home-subhead');

  // Create a timeline for sequential animations
  const tl = gsap.timeline();

  // Animate the home-page-title after a 2-second delay
  tl.from(homePageTitle, {
    y: '100%', // Start from the full height of the element
    opacity: 0, // Fade in from transparent
    duration: .7, // Animation duration
    delay: 2, // 2-second delay before the animation starts
    ease: 'power2.out', // Smooth easing
  });

  // Animate the home-subhead after the home-page-title animation completes
  tl.from(homeSubhead, {
    y: '50%', // Start from 50% below its original position
    opacity: 0, // Fade in from transparent
    duration: .5, // Animation duration
    ease: 'power1.out', // Smooth easing
  }, '+=.75'); // Start 0.5 seconds before the previous animation ends

}
if ($('.n-hero')[0]) {
  initHomepage();
  initHero();
}
